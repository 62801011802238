.majorMajor, .majorMinor, .minorMinor, .halfDiminished, .fullyDiminished{
flex: 1 1 auto;
margin: 10px;
padding: 30px;
text-align: center;
transition: 0.5s;
background-size: 200% auto;
color: rgb(0, 0, 0);
/* text-shadow: 0px 0px 10px rgba(0,0,0,0.2);*/
box-shadow: 0 0 20px #eee;
border-radius: 10px;
}

.hardB:hover {
background-position: right center; /* change the direction of the change here */
}

#preSeptRecTitle{
    position: absolute;
    top:30px;
    left:35%;
}

#preSeptQuest{
    position: relative;
    top:100px;
}

#preSeptRdy{
position: relative;
top:-50px;
}

#preSeptHome{
    position: relative;
    left:0;
    top:-50px;
}

#septRecEnd{
    position: relative;
    top:-40px;
}

#septRecYes{
position: relative;
top:120px;
left:-10%;
font-family: 'Bubblegum Sans', cursive;
	font-size: 25px;
    color: rgb(248, 3, 146)
}

#septRecNo{
  position: relative; 
  top:70px;
  left:10%;
  font-family: 'Bubblegum Sans', cursive;
	font-size: 25px;
    color: rgb(248, 3, 146) 
}

#septRecHome{
  position: relative; 
  left:0; 
  top:0px;
}

#septRecPic{
    position: relative;
    top:140px;
}

#btnSeptMapDiv{
    position: relative;
    top:100px;
}
