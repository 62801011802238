.numberPicL {
    max-width: 30px;
    max-height: 30px;
}

.infoL {
	font-family: 'Bubblegum Sans', cursive;
	font-size: 35px;
    color: white;
    
}

#l1{
    position: absolute;
    top:60px;
}

#choiceL1{
    position: absolute;
    top:500px;
    left:25%;
}

#choiceL2{
    position: absolute;
    top: 1700px;
}

#homeBtn2{
    position: absolute;
    left:870px;
}
#homeBtn3{
    position: absolute;
    left:870px;
}
#homeBtn4{
    position: absolute;
    left:1120px;
    top:2430px;
}

#homeBtn5{
    position: relative;
    left:0px;
}

#septBtn5{
    position: relative;
    left:0px;
}

#triadsBtn3{
    position: absolute;
    left:400px;
}
#triadsBtn4{
    position: absolute;
    left:150px;
}

#septBtn{
    position: absolute;
    left:400px;
}

.info1 {
	font-family: 'Bubblegum Sans', cursive;
	font-size: 35px;
    color: rgb(0, 0, 0);
    -webkit-text-stroke-width: 1.5px;
	-webkit-text-stroke-color: rgb(241, 22, 168);
    
}
.titleL{
    color: rgb(2, 2, 2);
	font-size: 40px;
	-webkit-text-stroke-width: 2.5px;
	-webkit-text-stroke-color: rgb(22, 227, 241);
}


.numberPic

.button{
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin: 10%;
    text-align: center
}



.btn-hover {
    width: 200px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    margin: 20px;
    height: 55px;
    text-align:center;
    border: none;
    background-size: 300% 100%;
    border-radius: 50px;
    moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
    position: relative;
}

.btn-hover:hover {
    background-position: 100% 0;
    moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}

.btn-hover:focus {
    outline: none;
}

.btn-hover.color-1 {
    background-image: linear-gradient(to right, #25aae1, #40e495, #30dd8a, #2bb673);
    box-shadow: 0 4px 15px 0 rgba(49, 196, 190, 0.75);
}

.btn-hover.color-3 {
    background-image: linear-gradient(to right, #667eea, #764ba2, #6B8DD6, #8E37D7);
    box-shadow: 0 4px 15px 0 rgba(116, 79, 168, 0.75);
}
.btn-hover.colorInver{
    background-image: linear-gradient(to right, #3b3d3c, rgb(91, 214, 80), rgb(13, 135, 156), rgb(224, 188, 26));
    box-shadow: 0 4px 15px 0 rgba(146, 175, 138, 0.75);
}

.btn-hover.colorSept{
    background-image: linear-gradient(to right, #6b0404, rgb(237, 252, 33), rgb(36, 0, 197),
     rgb(8, 116, 32));
    box-shadow: 0 4px 15px 0 rgba(197, 201, 196, 0.75);
    position: absolute;
    left:240px;
}
.btn-hover.colorTriads{
    background-image: linear-gradient(to right, #ca0799, rgb(139, 145, 235), rgb(7, 121, 31),
    rgb(225, 245, 50));
    box-shadow: 0 4px 15px 0 rgba(211, 187, 193, 0.75);
    position: absolute;
    left:20px;
}
.btn-hover.colorHome {
    background-image: linear-gradient(to right, #ed6ea0, #ec8c69, #f7186a , #FBB03B);
box-shadow: 0 4px 15px 0 rgba(236, 116, 149, 0.75);
position: absolute;
left:460px;
}
