
.titleS{
    color: rgb(2, 2, 2);
	font-size: 40px;
	-webkit-text-stroke-width: 2.5px;
	-webkit-text-stroke-color: rgb(22, 227, 241);
}


/* BUTTON'S FOR LEVEL PAGE */
.buttonS{
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin: 10%;
    text-align: center
}

.btn-hoverS {
    width: 200px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    margin: 20px;
    height: 55px;
    text-align:center;
    border: none;
    background-size: 300% 100%;
    border-radius: 50px;
    moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
    position: relative;
}

.btn-hoverS:hover {
    background-position: 100% 0;
    moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}

.btn-hoverS:focus {
    outFe: none;
}

.btn-hoverS.color-2 {
    background-image: linear-gradient(to right, #f5ce62, #e43603, #fa7199, #e85a19);
    box-shadow: 0 4px 15px 0 rgba(229, 66, 10, 0.75);
}

.btn-hoverS.color-6 {
    background-image: linear-gradient(to right, #009245, #FCEE21, #00A8C5, #D9E021);
    box-shadow: 0 4px 15px 0 rgba(83, 176, 57, 0.75);
}
.btn-hoverS.color-7 {
    background-image: linear-gradient(to right, #6253e1, #852D91, #A3A1FF, #F24645);
    box-shadow: 0 4px 15px 0 rgba(126, 52, 161, 0.75);
}
.btn-hoverS.color-8 {
    background-image: linear-gradient(to right, #29323c, #485563, #2b5876, #4e4376);
    box-shadow: 0 4px 15px 0 rgba(45, 54, 65, 0.75);
}
/* SOUND BUTTON  */
.jelly {
  cursor: pointer;
  max-width: 400px;
  left:37%;
	position: relative;
	padding: 2.5rem 7.5rem;
	border-radius: 3.75rem;
	line-height: 2.5rem;
	font-size: 2rem;
	font-weight: 600;
	border: 1px solid #012880;
	background-image: linear-gradient(-180deg, #FF89D6 0%, #C01F9E 100%);
	box-shadow: 0 1rem 1.25rem 0 rgba(22,75,195,0.50),
							0 -0.25rem 1.5rem rgba(110, 15, 155, 1) inset,
							0 0.75rem 0.5rem rgba(255,255,255, 0.4) inset,
							0 0.25rem 0.5rem 0 rgba(180, 70, 207, 1) inset;
}

.jelly span {
	color: transparent;
	background-image: linear-gradient(0deg, #EE82DA 0%, #FEFAFD 100%);
	-webkit-background-clip: text;
	background-clip: text;
	filter: drop-shadow(0 2px 2px hsla(290, 100%, 20%, 1));
}

.jelly::before {
	content: "";
	display: block;
	height: 0.25rem;
	position: absolute;
	top: 0.5rem;
	left: 50%;
	transform: translateX(-50%);
	width: calc(100% - 7.5rem);
	background: #fff;
	border-radius: 100%;
	opacity: 0.7;
	background-image: linear-gradient(-270deg, rgba(255,255,255,0.00) 0%, #FFFFFF 20%, #FFFFFF 80%, rgba(255,255,255,0.00) 100%);
}

.jelly::after {
	content: "";
	display: block;
	height: 0.25rem;
	position: absolute;
	bottom: 0.75rem;
	left: 50%;
	transform: translateX(-50%);
	width: calc(100% - 7.5rem);
	background: #fff;
	border-radius: 100%;
	filter: blur(1px);
	opacity: 0.05;
	background-image: linear-gradient(-270deg, rgba(255,255,255,0.00) 0%, #FFFFFF 20%, #FFFFFF 80%, rgba(255,255,255,0.00) 100%);
}

/*  EASY PAGE*/

.infoS {
	font-family: 'Bubblegum Sans', cursive;
	font-size: 35px;
    color: white;
    
}
.text1S{
	font-family: 'Bubblegum Sans', cursive;
	font-size: 35px;
    color: rgb(0, 0, 0);
    -webkit-text-stroke-width: 1.5px;
	-webkit-text-stroke-color: rgb(241, 22, 168); 
}

.containerS {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    width: 80vw;
    margin: 0 auto;
    min-height: 100vh;
  }
  .easyB {
    flex: 1 1 auto;
    margin: 10px;
    padding: 30px;
    text-align: center;
    transition: 0.5s;
    background-size: 200% auto;
    color: rgb(0, 0, 0);
   /* text-shadow: 0px 0px 10px rgba(0,0,0,0.2);*/
    box-shadow: 0 0 20px #eee;
    border-radius: 10px;
   }

    .easyB:hover {
    background-position: right center; /* change the direction of the change here */
  }
  
  .major{
    background-image: linear-gradient(to right, #f6d365 0%, #fda085 51%, #f6d365 100%);
  }
  
  .minor {
    background-image: linear-gradient(to right, #fbc2eb 0%, #a6c1ee 51%, #fbc2eb 100%);
  }
  
  .diminished {
    background-image: linear-gradient(to right, #84fab0 0%, #8fd3f4 51%, #84fab0 100%);
  }
  
  .augmented {
    background-image: linear-gradient(to right, #a1c4fd 0%, #c2e9fb 51%, #a1c4fd 100%);
  }


  /* MEDIUM BUTTON'S */
  .tableS{
      align: center;

  }
  .mediumB {
    flex: 1 1 auto;
    margin: 10px;
    padding: 30px;
    text-align: center;
    transition: 0.5s;
    background-size: 200% auto;
    color: rgb(0, 0, 0);
   /* text-shadow: 0px 0px 10px rgba(0,0,0,0.2);*/
    box-shadow: 0 0 20px #eee;
    border-radius: 10px;
   }

    .mediumB:hover {
    background-position: right center; /* change the direction of the change here */
  }
  
  .major{
    background-image: linear-gradient(to right, #f6d365 0%, #fda085 51%, #f6d365 100%);
  }
  
  .minor {
    background-image: linear-gradient(to right, #fbc2eb 0%, #a6c1ee 51%, #fbc2eb 100%);
  }
  
  .diminished {
    background-image: linear-gradient(to right, #84fab0 0%, #8fd3f4 51%, #84fab0 100%);
  }
  
  .augmented {
    background-image: linear-gradient(to right, #a1c4fd 0%, #c2e9fb 51%, #a1c4fd 100%);
  }


  /* ADVANCED BUTTONS */

.tableS{
      align: center;

  }
  .advancedB {
    flex: 1 1 auto;
    margin: 10px;
    padding: 30px;
    text-align: center;
    transition: 0.5s;
    background-size: 200% auto;
    color: rgb(0, 0, 0);
   /* text-shadow: 0px 0px 10px rgba(0,0,0,0.2);*/
    box-shadow: 0 0 20px #eee;
    border-radius: 10px;
   }

    .advancedB:hover {
    background-position: right center; /* change the direction of the change here */
  }
  
  .majorMajor{
    background-image: linear-gradient(to right, #f8cf46 0%, #f1653f 51%, #f8c006 100%);
  }
  
  .majorMinor{
    background-image: linear-gradient(to right, #f533be 0%, #095dee 51%, #fa04b4 100%);
  }
  
  .minorMinor {
    background-image: linear-gradient(to right, #08fa61 0%, #06a5f5 51%, #06fc60 100%);
  }
  
  .halfDiminished {
    background-image: linear-gradient(to right, #086aa3 0%, #0f9da1 51%, #26a3ec 100%);
  }

  .fullyDiminished{
    background-image: linear-gradient(to right, #be03f7 0%, #04dcf8 51%, #04f8f8 100%);
  }


  /* HARD BUTTONS */

.tableS{
    align: center;

}
.hardB {
  flex: 1 1 auto;
  margin: 10px;
  padding: 30px;
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: rgb(0, 0, 0);
 /* text-shadow: 0px 0px 10px rgba(0,0,0,0.2);*/
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
 }

  .hardB:hover {
  background-position: right center; /* change the direction of the change here */
}

.majorMajor{
  background-image: linear-gradient(to right, #f8cf46 0%, #f1653f 51%, #f8c006 100%);
}

.majorMinor{
  background-image: linear-gradient(to right, #f533be 0%, #095dee 51%, #fa04b4 100%);
}

.minorMinor {
  background-image: linear-gradient(to right, #08fa61 0%, #06a5f5 51%, #06fc60 100%);
}

.halfDiminished {
  background-image: linear-gradient(to right, #086aa3 0%, #0f9da1 51%, #26a3ec 100%);
}

.fullyDiminished{
  background-image: linear-gradient(to right, #745399 0%, #559597 51%, #50617e 100%);
}

.majorTriad{
    background-image: linear-gradient(to right, #f8f29d 0%, #f78686 51%, #fac564 100%);
}

.minorTriad{
    background-image: linear-gradient(to right, #f596e5 0%, #7577eb 51%, #fa85c9 100%);
}

.diminidhedTriad{
    background-image: linear-gradient(to right, #83ee91 0%, #78aeec 51%, #7af880 100%);
}

.augmentedTriad{
    background-image: linear-gradient(to right, #e9c4f5 0%, #b1ebf3 51%, #c2f7f7 100%);
}

#soundPlaySelect{
  position: absolute;
  top:100px;
  left:13%;
}

#difBtn{
  position: relative;
  left:10%;
}

#difBtnHome{
  position: relative;
  top:100px;
  left:-600px;
}

#playEzHome{
  position: relative;
  top:0px;
  left:0;
  
}

#medP{
  position: relative;
  top:-80px;
}

#soundBtnMed{
  position: relative;
  left:35%;
  top:300px;
}

.th{
  color:rgb(238, 255, 0);
  text-decoration: bald;
  font-size:large;
}

#medTable{
  position: relative;
  left:0;
  top:-20px;
}

#optionsMed{
  position: relative;
  top:50px;
  left:-25px;
}

#endDivMed{
  position: relative;
  top:400px;
  font-family: 'Bubblegum Sans', cursive;
    color: rgb(248, 3, 146);
  
}

#medYes{
position: relative;
left:-220px;
top:70px;
}

#medNo{
position: relative;
top:-5px;
left:170px;
}

#medHome{
position: absolute;
left:40%;
top:100px;

}

#testMed{
  position: relative;
  top:-100px;
}

#textHard{
  position: relative;
  top:50px;
}

#soundHard{
  position: relative;
  top:50px;
}

#hardHome{
  position: relative;
  left:0;
}

.infoOtherChords{
  font-family: 'Bubblegum Sans', cursive;
font-size: 25px;
  color: rgb(248, 3, 146)
}

#textAdvanced{
  position: relative;
  top:50px;
}

#soundAdvanced{
  position: relative;
  top:50px;
}

#advHome{
  position: relative;
  left:0;
}