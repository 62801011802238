/* body {
  text-align: center;
  background-image: url('../../assets/images/start3.gif');
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-size: inherit;
  background-size: 1600px 770px;
  text-align: center;
  background-color: rgb(134, 131, 131);
} */

.infoOther{
  color: red
}

.hAndW2 {
  /* color: rgb(255, 255, 255); */
  color: black;
  font-size: 70px;
  -webkit-text-stroke-width: 4px;
  -webkit-text-stroke-color: rgb(22, 227, 241);
}

.p2 {
  font-family: "Bubblegum Sans", cursive;
  font-size: 25px;

  color: rgb(250, 250, 250);
}

.button {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 10%;
  text-align: center;
}

.btn-hover {
  width: 200px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  margin: 20px;
  height: 55px;
  text-align: center;
  border: none;
  background-size: 300% 100%;
  border-radius: 50px;
  moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  flexdirection: "row";
  /* -webkit-background-clip: text; */
  /* -webkit-text-fill-color: transparent; */
}

.btn-hover:hover {
  background-position: 100% 0;
  moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.btn-hover:focus {
  outline: none;
}

.btn-hover.colorPlay {
  background-image: linear-gradient(
    to right,
    #8fe242,
    #e6be0b,
    rgb(218, 150, 49),
    rgb(77, 236, 215)
  );
  box-shadow: 0 4px 15px 0 rgba(116, 79, 168, 0.75);
}

.btn-hover.colorLearn {
  background-image: linear-gradient(
    to right,
    #2de76b,
    #e237cb,
    rgb(84, 169, 218),
    rgb(170, 55, 215)
  );
  box-shadow: 0 4px 15px 0 rgba(160, 66, 163, 0.75);
}

a:link {
  text-decoration: none;
}
a:visited {
  text-decoration: none;
}
