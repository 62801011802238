#recChoiceBtn{
    position: absolute;
    left:15%;
    top:50px;

}

#recBtn{
    position: relative;
    left:15%;
}