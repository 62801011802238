.head{
    font-family: 'Gotu', sans-serif;
    text-align: center;
    color: whitesmoke;
}

.ansBut2{
      margin-left: 10px;
      margin-right: 10px;
        font-size: 19px;
        font-family: 'Gotu', sans-serif;
        border: 3px solid whitesmoke;
        color:green;
        font-weight: bold;
        text-align: center;
        border-radius: 12px;
}

.text{
    font-family: 'Source Code Pro', monospace;
    font-size: 17px;
    text-align: center;
    color: whitesmoke;
}

.home{
    margin-top: 10px;
    font-size: 36px;
    font-family: 'Gotu', sans-serif;
    background-image: linear-gradient(to left, rgb(241, 28, 241), rgb(197, 39, 245), rgb(95, 209, 230), rgb(11, 184, 11), yellow, orange, red);   
    color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
    border-radius: 12px;
    border: 5px solid whitesmoke;
    border-radius: 12px;
    text-align: center;
}

#flatRdy{
    position: absolute;
    top:200px;
    left:45%;
}