.head{
    font-family: 'Gotu', sans-serif;
    text-align: center;
    color: whitesmoke;
}

.text{
    font-family: 'Source Code Pro', monospace;
    font-size: 17px;
    text-align: center;
    color: whitesmoke;
}

.home{
    margin-top: 10px;
    font-size: 36px;
    font-family: 'Gotu', sans-serif;
    background-image: linear-gradient(to left, rgb(241, 28, 241), rgb(197, 39, 245), rgb(95, 209, 230), rgb(11, 184, 11), yellow, orange, red); 
    color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
    border-radius: 12px;
    border: 5px solid whitesmoke;
    border-radius: 12px;
    text-align: center;
}
.ready{
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
      font-size: 25px;
      font-family: 'Gotu', sans-serif;
      border: 3px solid whitesmoke;
      background-image: linear-gradient(to left, rgb(241, 28, 241), rgb(197, 39, 245), rgb(95, 209, 230), rgb(11, 184, 11), yellow, orange, red);    
      color: transparent;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: bold;
      text-align: center;
      border-radius: 12px;
}

#btn0 {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    background-image: url("../assets/0.jpg");
    width: 85px;
    height: 105px;
    background-size: cover;
}
#btn1f {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    background-image: url("../assets/1f.jpg");
    width: 85px;
    height: 105px;
    background-size: cover;
}
#btn2f {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    background-image: url("../assets/2f.jpg");
    width: 85px;
    height: 105px;
    background-size: cover;
}
#btn3f {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    background-image: url("../assets/3f.jpg");
    width: 85px;
    height: 105px;
    background-size: cover;
}
#btn4f {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    background-image: url("../assets/4f.jpg");
    width: 85px;
    height: 105px;
    background-size: cover;
}
#btn5f {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    background-image: url("../assets/5f.jpg");
    width: 85px;
    height: 105px;
    background-size: cover;
}
#btn6f {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    background-image: url("../assets/6f.jpg");
    width: 85px;
    height: 105px;
    background-size: cover;
}
#btn7f {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    background-image: url("../assets/7f.jpg");
    width: 85px;
    height: 105px;
    background-size: cover;
}
#btn1s {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    background-image: url("../assets/1s.jpg");
    width: 85px;
    height: 105px;
    background-size: cover;
}
#btn2s {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    background-image: url("../assets/2s.jpg");
    width: 85px;
    height: 105px;
    background-size: cover;
}
#btn3s {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    background-image: url("../assets/3s.jpg");
    width: 85px;
    height: 105px;
    background-size: cover;
}
#btn4s {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    background-image: url("../assets/4s.jpg");
    width: 85px;
    height: 105px;
    background-size: cover;
}
#btn5s {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    background-image: url("../assets/5s.jpg");
    width: 85px;
    height: 105px;
    background-size: cover;
}
#btn6s {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    background-image: url("../assets/6s.jpg");
    width: 85px;
    height: 105px;
    background-size: cover;
}
#btn7s {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    background-image: url("../assets/7s.jpg");
    width: 85px;
    height: 105px;
    background-size: cover;
}

#quizRdy{
    position: absolute;
    top:200px;
    left:45%;
}

.quiz{
    position: absolute;
    top:150px;
}