.App {
  text-align: left;
}

body{
  background-image: none;
}
.body {
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
}

.head {
  border-bottom-style: solid;
  border-color: #cccccc;
  border-width: 2px;
  text-align: center;
  color: #ffffff;
  font-family: "Georgia", Times, Times New Roman, serif;
  background-color: #ffffff;
  width: 100%;
  padding-top: 8px;
  position: absolute;
  top: 0;
}
#title {
  background-color: #883043;
  display: inline;
  padding: 5px;
  padding-top: 8px;
}
.navigation {
  background-color: #ffffff;
  color: #aa9e9e;
  display: inline;
  padding-top: 20px;
  margin-left: 285px;
}
#subtitle {
  color: #aa9e9e;
  display: inline;
  font-family: "Arizonia";
}
a:link {
  text-decoration: none;
  color: whitesmoke;
}
a:visited {
  text-decoration: none;
  color: #777777;
}
.about {
  position: relative;
  top: 75px;
  border-left: 1px solid #cccccc;
  border-right: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
  border-top: 1px solid #cccccc;
  width: 960px;
  margin: 0 auto;
  background-color: #ffffff;
  font-family: "Arial", "Helvetica Neue", Helvetica, sans-serif;
  color: #aa9e9e;
  padding-left: 20px;
}
html {
  background-image: url(./assets/pics/symphony.png);
}

h2,
h4 {
  color: #883043;
  padding-top: -5px;
  padding-left: 20px;
  padding-bottom: 10px;
  font-family: "Georgia", Times, Times New Roman, serif;
}
#mepic {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  float: left;
  height: 200px;
  width: 180px;
}

#submitBtn{
position: relative;
top:-96px;
}

hr {
  margin-right: 20px;
  color: #cccccc;
}
.contactbox {
  padding-bottom: 20px;
  padding-top: -20px;
  position: relative;
  top: 75px;
  border-left: 1px solid #cccccc;
  border-right: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
  border-top: 1px solid #cccccc;
  width: 960px;
  height: 600px;
  margin: 0 auto;
  background-color: #ffffff;
  font-family: "Arial", "Helvetica Neue", Helvetica, sans-serif;
  color: #777777;
}
#pOne {
  position:absolute;
  top: 100px;
  left:200px;
}
#pTwo {
  position:absolute;
  top: 160px;
  left:200px;
}
#pThree {
  position:absolute;
  top: 250px;
  left:200px;
}
#pFour {
  position:absolute;
  top: 300px;
  left:0px;
}
#pFive {
  position:absolute;
  top: 380px;
  left:0px;
}
#pSix {
  position:absolute;
  top: 460px;
  left:0px;
}
#pSeven {
  position:absolute;
  top: 540px;
  left:0px;
}
#flintBox {
  height: 1000px;
}
#bonezBox {
  height: 950px;
}
#burgerBox {
  height: 800px;
}
#weatherBox {
  height: 650px;
}
#pythonBox {
  height: 950px;
}
#bsnBox {
  height: 700px;
}
.break {
  padding-left: 20px;
  padding-right: 20px;
}

.hereLink{
  background-color: #883043;
  color: whitesmoke;
}
.button {
  background-color: #883043;
  border: none;
  color: white;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
}
.button:hover {
  background-color: black;
}
.linkpic {
  box-sizing: border-box;
  left: 20px;
  margin-bottom: 30px;
  margin-left: 20px;
  border-style: solid;
  border-width: 1px;
  border-color: #777777;
}
.btn1,
.btn2,
.btn3,
.btn4,
.btn5,
.btn6,
.btn7 {
  background-color: #883043;
  border: none;
  color: white;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
}
#btn1 {
  position: absolute;
  left: 20px;
  padding-left: 23px;
  padding-right: 23px;
  color:whitesmoke;
}
#btn1 #bonezPop {
  visibility: hidden;
  width: 250px;
  background-color: #883043;
  color: #aa9e9e;
  text-align: center;
  border-radius: 6px;
  border-style: solid;
  border-color: black;
  border-width: 2px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
}
#btn1:hover #bonezPop {
  visibility: visible;
}
.container #btn1 {
  position: absolute;
  top: -2px;
  left: 20px;
  font-size: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 53px;
  padding-right: 53px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

#btn2 #pythonPop {
  visibility: hidden;
  width: 250px;
  background-color: #883043;
  color: #aa9e9e;
  text-align: center;
  border-radius: 6px;
  border-style: solid;
  border-color: black;
  border-width: 2px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
}
#btn2:hover #pythonPop {
  visibility: visible;
}

#btn3 #weatherPop {
  visibility: hidden;
  width: 250px;
  background-color: #883043;
  color: #aa9e9e;
  text-align: center;
  border-radius: 6px;
  border-style: solid;
  border-color: black;
  border-width: 2px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
}
#btn3:hover #weatherPop {
  visibility: visible;
}

#btn4 #bsnPop {
  visibility: hidden;
  width: 250px;
  background-color: #883043;
  color: #aa9e9e;
  text-align: center;
  border-radius: 6px;
  border-style: solid;
  border-color: black;
  border-width: 2px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
}

#btn4{
  color: whitesmoke;
}

#btn4:hover #bsnPop {
  visibility: visible;
}

#btn2 {
  padding-left: 32px;
  padding-right: 32px;
  top:-2px;
  color:whitesmoke;
}

#btn3 {
  padding-left: 53px;
  padding-right: 53px;
  color: whitesmoke;
}
#btn5 {
  position: absolute;
  left: 20px;
  top: -2px;
  padding-left: 59px;
  padding-right: 59px;
  font-size: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
  color: whitesmoke;
}
#btn6 {
  position: absolute;
  left: 20px;
  top: -3px;
  padding-left: 58px;
  padding-right: 57px;
  font-size: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
  text-align: left;
  display: inline;
  white-space: nowrap;
  color: whitesmoke;
}

.container .btn5:hover {
  background-color: black;
  cursor: pointer;
}
.container .btn2 {
  position: absolute;
  top: 0px;
  left: 20px;
  font-size: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 34px;
  padding-right: 34px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.container .btn6 {
  position: absolute;
  top: -2px;
  left: -40px;
  font-size: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 34px;
  padding-right: 34px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.container .btn2:hover {
  background-color: black;
}
.container .btn3 {
  position: absolute;
  top: -2px;
  left: 20px;
  font-size: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 60px;
  padding-right: 40px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.container .btn3:hover {
  background-color: black;
}
.container .btn4 {
  position: absolute;
  top: -2px;
  left: 20px;
  font-size: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 60px;
  padding-right: 40px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}
.container .btn5 {
  position: absolute;
  top: -2px;
  left: -40px;
  font-size: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 60px;
  padding-right: 40px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.container .btn4:hover {
  background-color: black;
}

#linkOne {
  position: absolute;
  top: 100px;
  left: 0px;
}

#linkThree {
  position: absolute;
  top: 100px;
  left: 460px;
}

#linkFive {
  position: absolute;
  top: 320px;
}
.container .btn6:hover {
  background-color: black;
}

#linkSix {
  position: absolute;
  top: 320px;
  left: 230px;
  /* float: left; */
}
#linkTwo {
  position: absolute;
  top: 100px;
  left: 230px;
}
#linkTwo #btn1:hover {
  background-color: black;
}
#bonezPic {
  max-width: 100%;
  max-height: 500px;
}
#flintPic {
  max-width: 100%;
  max-height: 500px;
}
#restPic {
  max-width: 100%;
  max-height: 500px;
}
#restBox {
  height: 750px;
}
#burgerPic {
  max-width: 100%;
  max-height: 500px;
}
#linkSeven {
  position: absolute;
  top: 321px;
  left: 460px;
}
#linkFour {
  position: absolute;
  top: 100px;
  left: 690px;
}

#linkFour #btn7:hover {
  background-color: black;
}

#btn7 {
  white-space: nowrap;
  color: whitesmoke;
}

#btn7 #restPop {
  visibility: hidden;
  width: 250px;
  background-color: #883043;
  color: #aa9e9e;
  text-align: center;
  border-radius: 6px;
  border-style: solid;
  border-color: black;
  border-width: 2px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  white-space: normal;
}
#btn7:hover #restPop {
  visibility: visible;
}
.container #btn7 {
  position: absolute;
  top: -2px;
  left: 20px;
  font-size: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 46px;
  padding-right: 46px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

#pythonPic {
  max-width: 100%;
  max-height: 500px;
}
#weatherPic {
  max-width: 100%;
  max-height: 500px;
}
#bsnPic {
  max-width: 100%;
  max-height: 500px;
}
a:hover {
  background-color: #883043;
 
}

#btn5 #burgerPop {
  visibility: hidden;
  width: 250px;
  background-color: #883043;
  color: #aa9e9e;
  text-align: center;
  border-radius: 6px;
  border-style: solid;
  border-color: black;
  border-width: 2px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
}
#btn5:hover #burgerPop {
  visibility: visible;
}

#btn6 #flintPop {
  visibility: hidden;
  width: 250px;
  background-color: #883043;
  color: #aa9e9e;
  text-align: center;
  border-radius: 6px;
  border-style: solid;
  border-color: black;
  border-width: 2px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  white-space: normal;
}
#btn6:hover #flintPop {
  visibility: visible;
}

.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  color: #883043;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
}

/* The Close Button */
.close-btn {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close-btn:hover,
.close-btn:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

@media screen and (min-width: 769px) and (max-width: 980px) {
  #title {
    position: static;
    float: left;
    display: block;
  }
  #subtitle {
    text-align: left;
    margin-top: 30px;
    position: static;
    left: 0;
    display: block;
    float: left;
  }
  .navigation {
    min-width: 100%;
    margin-left: 10px;
    padding-top: 5px;
    margin-top: 40px;
    position: static;
    left: 0;
    display: block;
  }
  #box {
    margin-top: 50px;
    height: 780px;
  }
  .about {
    margin-top: 150px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    width: 750px;
    height: 530px;
  }
  #pOne {
    float: left;
    position: absolute;
    top: 90px;
    right: 10px;
    left: 50px;
    margin-left: 170px;
    max-width: 500px;
    overflow-wrap: break-word;
    word-wrap: break-word;
  }
  #pTwo {
    float: none;
    position: absolute;
    top: 180px;
    right: 10px;
    left: 50px;
    margin-left: 170px;
    max-width: 500px;
    overflow-wrap: break-word;
    word-wrap: break-word;
  }
  #pThree {
    float: none;
    position: absolute;
    top: 330px;
    left: 0px;
    max-width: 700px;
    overflow-wrap: break-word;
    word-wrap: break-word;
  }
  #pFour {
    float: none;
    position: absolute;
    top: 400px;
    max-width: 720px;
    overflow-wrap: break-word;
    word-wrap: break-word;
  }
  #pFive {
    float: none;
    position: absolute;
    top: 490px;
    max-width: 700px;
    overflow-wrap: break-word;
    word-wrap: break-word;
  }
  #pSix {
    float: none;
    position: absolute;
    top: 600px;
    max-width: 700px;
    overflow-wrap: break-word;
    word-wrap: break-word;
  }
  #pSeven {
    float: none;
    position: absolute;
    top: 690px;
    max-width: 700px;
    overflow-wrap: break-word;
    word-wrap: break-word;
  }

  .contactbox {
    max-width: 600px;
    margin-top: 50px;
  }
  input {
    max-width: 530px;
  }
  textarea {
    max-width: 530px;
  }
  #placeholders {
    margin-top: 50px;
    height: 1000px;
  }
  #contact {
    margin-top: 50px;
  }

  #linkThree {
    top: 330px;
    left: 0px;
  }
  #linkFour {
    top: 330px;
    left: 230px;
  }
  #linkFive {
    top: 560px;
  }
  #linkSix {
    top: 560px;
  }
  #linkSeven {
    top: 780px;
    left: 0;
  }
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  #title {
    position: static;
    left: 0;
    display: block;
    margin-left: -300px;
    padding-left: 20px;
  }
  #subtitle {
    text-align: left;
    margin-bottom: 5px;
    position: static;
    left: 1;
    display: block;
    float: left;
    margin-top: -75px;
    margin-left: 310px;
    padding-bottom: 55px;
    padding-top: 15px;
    background-color: white;
    padding-left: 20px;

    padding-right: 40px;
  }
  .navigation {
    min-width: 100%;
    margin-left: 0px;
    text-align: left;
    padding-top: 5px;
    padding-bottom: 5px;
    position: static;
    left: 0;
    display: block;
    margin-top: -40px;
    text-align: center;
    float: left;
  }
  .about {
    margin-top: 150px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    width: 600px;
    height: 550px;
  }
  #pOne {
    float: left;
    position: absolute;
    top: 100px;
    left: 190px;
    /* right: 10px; */
    max-width: 350px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    margin-left: 10px;
  }
  #pTwo {
    float: none;
    position: absolute;
    top: 180px;
    right: 10px;
    max-width: 350px;
    overflow-wrap: break-word;
    word-wrap: break-word;
  }
  #pThree {
    float: none;
    position: absolute;
    top: 300px;
    left: 0px;
    max-width: 600px;
    overflow-wrap: break-word;
    word-wrap: break-word;
  }
  #pFour {
    float: none;
    position: absolute;
    top: 370px;
    max-width: 600px;
    overflow-wrap: break-word;
    word-wrap: break-word;
  }
  #pFive {
    float: none;
    position: absolute;
    top: 460px;
    max-width: 600px;
    overflow-wrap: break-word;
    word-wrap: break-word;
  }
  #pSix {
    float: none;
    position: absolute;
    top: 560px;
    max-width: 600px;
    overflow-wrap: break-word;
    word-wrap: break-word;
  }
  #pSeven {
    float: none;
    position: absolute;
    top: 650px;
    max-width: 600px;
    overflow-wrap: break-word;
    word-wrap: break-word;
  }
  #box {
    height: 720px;
  }

  .contactbox {
    margin-top: 100px;
    max-width: 600px;
  }
  input {
    max-width: 530px;
  }
  textarea {
    max-width: 530px;
  }
  #placeholders {
    margin-top: 100px;
    height: 1000px;
  }

  #contact {
    margin-top: 100px;
  }
  #box {
    margin-top: 100px;
  }
  #btn1:hover #bonezPop,
  #btn2:hover #pythonPop,
  #btn3:hover #weatherPop,
  #btn4:hover #bsnPop,
  #btn5:hover #burgerPop,
  #btn6:hover #flintPop,
  #btn7:hover #restPop {
    visibility: hidden;
  }
  .break {
    padding-right: 10px;
  }

  #linkThree {
    position: absolute;
    top: 1040px;
    left: -320px;
  }

  #linkSeven {
    position: absolute;
    float: left;
    top: 805px;
    left: -540px;
  }
  #linkOne {
    position: absolute;
    top: 100px;
  }
  #linkTwo {
    position: absolute;
    top: 100px;
  }
  #linkThree {
    position: absolute;
    top: 330px;
    left: 0px;
  }
  #linkFour {
    position: absolute;
    top: 330px;
    left: 230px;
  }
  #linkFive {
    position: absolute;
    top: 560px;
    left: 0px;
  }
  #linkSix {
    position: absolute;
    top: 560px;
    left: 230px;
  }
  #linkSeven {
    position: absolute;
    top: 790px;
    left: 0px;
  }
}

@media screen and (max-width: 640px) {
  #title {
    position: static;
    left: 0;
    display: block;
    margin-bottom: 100px;
  }
  #subtitle {
    text-align: left;
    padding-bottom: 5px;
    margin-bottom: 5px;
    position: absolute;
    top: 70px;
    left: 0;
    display: block;
  }
  #box {
    height: 1750px;
    width: 100%;
  }
  .navigation {
    min-width: 100%;
    margin-left: 0px;
    text-align: left;
    padding-top: 5px;
    margin-top: 5px;
    padding-bottom: 5px;
    position: absolute;
    top: 200px;
    left: 0;
    display: block;
  }
  .about {
    margin-top: 150px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    width: 260px;
    height: 1200px;
    margin-left: -10px;
  }
  #pOne {
    float: none;
    position: absolute;
    top: 320px;
    left: 0px;
    max-width: 250px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    margin-left: -10px;
  }
  #pTwo {
    float: none;
    position: absolute;
    top: 460px;
    left: 0px;
    max-width: 250px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    margin-left: -10px;
  }
  #pThree {
    float: none;
    position: absolute;
    top: 690px;
    left: 0px;
    max-width: 250px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    margin-left: -10px;
  }
  #pFour {
    float: none;
    position: absolute;
    top: 850px;
    max-width: 250px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    margin-left: -10px;
  }
  #pFive {
    float: none;
    position: absolute;
    top: 1100px;
    max-width: 250px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    margin-left: -10px;
  }
  #pSix {
    float: none;
    position: absolute;
    top: 1410px;
    max-width: 250px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    margin-left: -10px;
  }
  #pSeven {
    float: none;
    position: absolute;
    top: 1630px;
    max-width: 250px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    margin-left: -10px;
  }
  #mepic {
    margin-left: -10px;
  }

  .contactbox {
    margin-top: 170px;
    max-width: 250px;
  }

  input {
    max-width: 230px;
  }
  textarea {
    max-width: 230px;
  }

  #linkSix {
    float: none;
    position: absolute;
    top: 800px;
    left: 0px;
  }
  #linkTwo {
    float: none;
    position: absolute;
    top: 570px;
    left: 0px;
  }
  #burgerPic {
    max-width: 250px;
    float: none;
    position: absolute;
    left: 0px;
    margin-bottom: 100px;
  }
  #burgerpic {
    position: absolute;
    bottom: -1800px;
    top: 460px;
    left: 0px;
  }
  #flintpic {
    position: absolute;
    bottom: -50px;
    top: 630px;
    left: 0px;
  }
  .btn1 {
    width: 94px;
  }
  .btn2 {
    width: 135px;
  }
  .btn3 {
    width: 94px;
  }
  .btn4 {
    width: 100px;
  }
  #btn5 {
    position: absolute;
    left: 20px;
  }
  #btn6 {
    position: absolute;
    top: 630px;
    left: 20px;
  }
  .break {
    max-width: 250px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    margin-left: -10px;
  }
  #placeholders {
    height: 1750px;
  }

  #flintBox {
    height: 1450px;
  }
  #bonezBox {
    height: 1050px;
  }

  #restBox {
    height: 950px;
  }
  #weatherBox {
    height: 900px;
  }
  #pythonBox {
    height: 1200px;
  }
  #bsnBox {
    height: 550px;
  }
  #btn1:hover #bonezPop,
  #btn2:hover #pythonPop,
  #btn3:hover #weatherPop,
  #btn4:hover #bsnPop,
  #btn5:hover #burgerPop,
  #btn6:hover #flintPop,
  #btn7:hover #restPop {
    visibility: hidden;
  }

  #p1 {
    margin-top: 100px;
  }
  #linkOne {
    position: absolute;
    top: -550px;
  }
  #linkTwo {
    position: absolute;
    top: 300px;
  }
  #linkThree {
    position: absolute;
    top: 520px;
    left: 0px;
  }
  #linkFour {
    position: absolute;
    top: 740px;
    left: 0px;
  }
  #linkFive {
    position: absolute;
    top: 960px;
    left: 0px;
  }
  #linkSix {
    position: absolute;
    top: 1180px;
    left: 0px;
  }
  #linkSeven {
    position: absolute;
    top: 1400px;
    left: 0px;
  }
}
