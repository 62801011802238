.button{
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin: 10%;
    text-align: center
}



.btn-hover {
    width: 200px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    margin: 20px;
    height: 55px;
    text-align:center;
    border: none;
    background-size: 300% 100%;
    border-radius: 50px;
    moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
    position: relative;
}

.btn-hover:hover {
    background-position: 100% 0;
    moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}

.btn-hover:focus {
    outline: none;
}

.colorReady {
    background-image: linear-gradient(to right, #8625e1, #40dd2b, #ae5ec2, #2bb673);
    box-shadow: 0 4px 15px 0 rgba(49, 196, 190, 0.75);
}

.majorTriad, .minorTriad, .diminidhedTriad, .augmentedTriad{
flex: 1 1 auto;
margin: 10px;
padding: 30px;
text-align: center;
transition: 0.5s;
background-size: 200% auto;
color: rgb(0, 0, 0);
/* text-shadow: 0px 0px 10px rgba(0,0,0,0.2);*/
box-shadow: 0 0 20px #eee;
border-radius: 10px;
}

.hardB:hover {
background-position: right center; /* change the direction of the change here */
}

#preTreRecTitle{
    position: absolute;
    top:30px;
    left:35%;
}

#preTreQuest{
    position: relative;
    top:100px;
}

#preTriRdy{
position: relative;
top:-50px;
}

#preTriHome{
    position: relative;
    left:0;
    top:-50px;
}

#triRecEnd{
    position: relative;
    top:-40px;
}

#triRecYes{
position: relative;
top:20px;
font-family: 'Bubblegum Sans', cursive;
	font-size: 25px;
    color: rgb(248, 3, 146)
}

#triRecNo{
  position: relative; 
  top:20px;
  font-family: 'Bubblegum Sans', cursive;
	font-size: 25px;
    color: rgb(248, 3, 146) 
}

#triRecHome{
  position: relative; 
  left:0; 
}

.down30{
    position: absolute;
    top:30px;
    left:38%;
}

#triRecPic{
    position: relative;
    top:140px;
}



