.button{
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin: 10%;
    text-align: center
}



.btn-hover {
    width: 200px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    margin: 20px;
    height: 55px;
    text-align:center;
    border: none;
    background-size: 300% 100%;
    border-radius: 50px;
    moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
    position: relative;
}

.btn-hover:hover {
    background-position: 100% 0;
    moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}

.btn-hover:focus {
    outline: none;
}

.colorBuild {
    background-image: linear-gradient(to right, #66c6f0, #095e45, #e682f3, #7a8f1d);
    box-shadow: 0 4px 15px 0 rgba(106, 172, 216, 0.75);
}

.colorRecognize {
    background-image: linear-gradient(to right, #c4c007, #530b44, rgb(211, 168, 49), #8E37D7);
    box-shadow: 0 4px 15px 0 rgba(116, 79, 168, 0.75);
}

#theoryPlayChoice{
    position: absolute;
    top:180px;
    left:35%;
}

#theoryPlayChoiceHome{
    position: relative;
    left:-50%;
    top:100px;
}