html{
    color:rgb(238, 255, 0);
    text-decoration: bald;
    font-size:large;
}


.infoOtherChords{
    font-family: 'Bubblegum Sans', cursive;
	font-size: 35px;
    color: rgb(248, 3, 146)
}


.button{
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin: 10%;
    text-align: center
}

.noteBtn{
    /* position: relative; */
    max-width: 100px;
    max-height: 90px;
    
}

.imgBtn{
    width: 80px;
    height: 85px;
    padding: 0px;
}



.btn-hover {
    width: 200px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    margin: 20px;
    height: 55px;
    text-align:center;
    border: none;
    background-size: 300% 100%;
    border-radius: 50px;
    moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
    position: relative;
}

.btn-hover:hover {
    background-position: 100% 0;
    moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}

.btn-hover:focus {
    outline: none;
}

.colorRoot {
    background-image: linear-gradient(to right, #045375, #125e09, #177548, #225752);
    box-shadow: 0 4px 15px 0 rgba(49, 196, 190, 0.75);
}

.colorInversions {
    background-image: linear-gradient(to right, #e2405b, #b31e92, #6B8DD6, #8E37D7);
    box-shadow: 0 4px 15px 0 rgba(116, 79, 168, 0.75);
}


.picSize{
    height: 390px;
    width: 105px;
}

#buildFirstChoice{
    position: absolute;
    top:30px;
}

#buildChoice{
    position: relative;
    top:150px;
}

#build1Btn{
    position: relative;
    top:-300px;
    left:5%;
}

#build1Home{
    position: relative;
    top:80px;
    left:-24%;

}

#inversionDivAll{
    position: absolute;
    top:40px;
}

#buildInvEnd{
    position: relative;
   
}

#invMapBtn{
    position: relative;
    top:-120px;
}

#testInvDiv{
    position: relative;
    top:-100px;
}

#someId{
    position: relative;
    top:-40px;
}

#incomChordPic{
    position: relative;
    top:-20px;
    left:-13%;
}

#mapBtns{
    position: relative;
    top:-40px;
}

#buildInvYes{
    position: relative;
    top:-70px;
    left:-200px;
}

#buildInvNo{
    position: relative;
    top:-145px;
    left:200px;
}

#buildInvHome{
    position: relative;
    top:-180px;
    left:0;

}

#missingInvPic{
    position: relative;
    top:-70px;
}

#buildRTitle{
    position: absolute;
    top:30px;
    left:35%;
}

#buildRDirect{
    position: relative;
    top:70px;
}

#buildRBtns{
    position: relative;
    top:-50px;
}

#buildRMapBtn{
    position: relative;
    top:-90px;
}

#buildRDirections{
    position: relative;
    top:-30px;
}

#buildREnd{
    position: relative;
    top:-100px;
}

#buildRHome{
    position: relative;
    left:0;
}

